<template>
	<router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
@import '@/assets/css/common.scss';
html,body{
	margin: 0;
	padding: 0;
}
</style>
