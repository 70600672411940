import { createWebHistory, createRouter } from 'vue-router'
 
// 定义路由配置
const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('@/views/index/TopNav.vue'),
		children:[
			{
				id:1,
				path: '/',
				name: 'WebIndexs',
				component: () => import('@/views/index/WebIndex.vue'),
				meta: {
					title: '首页'
				}
			},
			{
				id:2,
				path: '/ClassiFication',
				name: 'ClassiFication',
				component: () => import('@/views/index/ClassiFication.vue'),
				meta: {
					title: '分类活动'
				}
			},
			{
				id:2,
				path: '/Details',
				name: 'Details',
				component: () => import('@/views/details/DetailsIndex.vue'),
				meta: {
					title: '活动详情'
				}
			}
		]
    },
]
 
// 创建路由实例
const router = createRouter({
    history: createWebHistory(),    // 导航历史记录模式
    routes
})
 
 
// 导出实例
export default router